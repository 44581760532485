@import "variables";

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  color: #fff;
  width: 100%;
  margin-bottom: 16px;

  &--listProducts{
    margin-bottom: 0;
  }
}

.select-styled {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.15s ease-in;
  width: 100%;
  border: 1px solid #D2D2D2;
  padding: 14px 24px 14px 12px;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 300;
  letter-spacing: .25px;
  color: #878787;
  border-radius: 8px;

  &:after{
    font-family: "Font Awesome 5 Pro";
    content: "\f0d7";
    position: absolute;
    color: #458CCB;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 18px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  &--listProducts{
    border: 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: $colorSecondary;

    &:after{
      padding: 0;
    }
  }
}

.select-styled.active:after {
  content: "\f0d8";
}

.select-options {
  display: none;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  list-style: none;
  background-color: #fff;
  border: 1px solid #DBDBDB;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
  margin-top: 3px;
  padding: 10px 0;
  position: absolute;

  li{
    padding: 10px 0;
    transition: all 0.15s ease-in;
    color: #878787;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: .25px;
    font-weight: 300;
    border-top: 0;
    margin: 10px 0;
    text-indent: 24px;

    &:hover{
      background-color: $colorPrimary;
      color: #fff;
    }
  }

  &:before{
    border-bottom-color: #dbdbdb !important;
    border-width: 8px !important;
    margin-left: -8px;
  }

  &:after{
    border-bottom-color: #fff !important;
    border-width: 6px !important;
    margin-left: -6px;
  }

  &:before, &:after{
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &--listProducts{
    border: 0;

    li{
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      color: $colorSecondary;
      text-indent: 0;
      padding: 5px 12px;
      margin: 5px 0;
    }

    &:before, &:after{
      display: none;
    }
  }
}

@media screen and (max-width: 991px){
  .select-styled{
    padding: 10px 18px;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: .19px;

    li{
      font-size: 15px;
      line-height: 18px;
      letter-spacing: .19px;
      padding: 5px 0;
      margin: 5px 0;
      text-indent: 18px;
    }

    &--listProducts{
      font-size: 12px;
      line-height: 15px;
    }
  }


}
